// @flow

import { stringify } from 'qs'
import moment from 'moment'

import * as types from '../types'
import Api from '../../lib/api'

/**
 * Login a user
 * @param {Object} config
 */
export const loadReviews = (
  page: string | number = 1,
  query: string | null,
  sortBy: string | null = 'date',
  sortDir: string | null = 'desc',
  institution: string | null = null
): Function => (dispatch: Function, state: Function) => {
  const { user } = state()
  const { accessToken } = user

  const params = stringify({
    page,
    query,
    sortBy,
    sortDir,
    institution,
    pageSize: 10,
    accessToken,
  })

  return Api.get(`records?${params}`, {
    baseURL: process.env.CLOUD_FUNCTIONS_API_BASE_URL,
    // params: {
    //   ...config,
    // },
  })
    .then((result) => {
      const { data } = result

      dispatch({ type: types.SET_REVIEWS, data })
      return result
    })
    .catch((reason) => {
      return reason
    })
}

export const loadReviewDetail = (recordId: string) => (
  dispatch: Function,
  state: Function
) => {
  const { user } = state()
  const { accessToken } = user

  const params = [`accessToken=${accessToken}`, `recordId=${recordId}`].join(
    '&'
  )

  dispatch({ type: types.LOAD_REVIEWS_DETAIL })

  return Api.get(`records-detail?${params}`, {
    baseURL: process.env.CLOUD_FUNCTIONS_API_BASE_URL,
  })
    .then((result) => {
      const { data } = result

      dispatch({ type: types.SET_REVIEWS_DETAIL, data })
    })
    .catch((reason) => {
      return reason
    })
}

export const createReview = (payload: Object) => (
  dispatch: Function,
  state: Function
) => {
  const { user } = state()
  const { accessToken, id: author } = user
  const {
    institution,
    otherInstitution,
    supervisorFirstName,
    supervisorLastName,
    supervisorTitle,
    profTitle,
    profFirstName,
    profLastName,
    beginOfSupervisorship,
    endOfSupervisorship,
    doctoralThesisType,
    methods,
    positiveAspects,
    negativeAspects,
    ratingOverall,
    ratingCompetence,
    ratingStructure,
    ratingConsultations,
    ratingCommitment,
    ratingPromotion,
  } = payload
  const category = parseInt(institution, 10)
  const unix = (date) => moment(date).unix()

  const payloadFormatted = stringify({
    category,
    author,
    date: moment().unix(),
    fields: {
      '54': supervisorLastName,
      '55': supervisorFirstName,
      '57': supervisorTitle,
      '58': profTitle,
      '59': profFirstName,
      '60': profLastName,
      '61': unix(beginOfSupervisorship),
      '73': unix(endOfSupervisorship),
      '63': positiveAspects.replace(/\r?\n/g, '<br />'),
      '64': negativeAspects.replace(/\r?\n/g, '<br />'),
      '65': ratingOverall,
      '56': ratingCompetence,
      '66': ratingStructure,
      '67': ratingConsultations, // ratingStructure,
      '68': ratingCommitment,
      '69': ratingPromotion,
      '72': otherInstitution, // custom institution as notice
      '74': doctoralThesisType,
      '75': methods.join(','),
    },
  })

  return Api.post(`cms/records/${process.env.DATABASE_ID}`, payloadFormatted, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((result) => {
      const { data } = result

      dispatch({ type: types.CREATE_REVIEW }, data)
    })
    .catch((reason) => {
      throw reason
    })
}
