// @flow
import React from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

import * as v from '../../config/variables'

type Props = {
  loading: boolean,
  text?: string,
}

const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`

const Text = styled.span`
  margin: 0.5rem 0 0;
  display: block;
  font-size: 0.8rem;
  display: none;
  /* color: ${v.gray}; */
`

export const LoadingIndicator = (props: Props) => {
  const { loading, text } = props

  if (!loading) return null

  return (
    <LoadingBox>
      <ClipLoader loading={loading} color={v.brandColor} />
      <Text>{text || 'Lade…'}</Text>
    </LoadingBox>
  )
}
