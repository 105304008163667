// @flow
// COLORS
export const white = '#ffffff'
export const black = '#000000'
export const blue = '#3d6594'
export const disscheckGreen = '#578e30'
export const gray = '#828E99'
export const grayDark = 'dimgray'
export const grayLight = '#c0c0c0'
export const grayLighter = 'whitesmoke'
export const orange = '#ff9800'

export const brandColor = disscheckGreen

export const textColor = black

// SIZES
export const borderRadiusDefault = '3px'

// FONTS
export const fontFamilyAccent = '"Roboto Condensed","Arial Narrow",sans-serif'
export const fontFamilySansSerif =
  '"Open Sans", BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif'
export const fontFamilyBase = fontFamilySansSerif
