// @flow
import React from 'react'
// import { Link } from 'gatsby'
import styled from 'styled-components'

import { Container as ContainerGeneric, Row, Col } from 'reactstrap'
import * as v from '../../config/variables'

const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  background-color: ${v.brandColor};
  color: ${v.white};

  a {
    color: ${v.white};
  }
`

const Inner = styled(ContainerGeneric)`
  /* display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start; */

  @media (min-width: 768px) {
    /* justify-content: space-between; */
  }
`

const Copyright = styled.p`
  text-align: center;

  @media (min-width: 576px) {
    text-align: left;
    margin: 0;
  }
`

const SmallText = styled.p`
  text-align: center;
  font-size: 0.8rem;
  margin: 1rem 0;

  @media (min-width: 576px) {
    text-align: right;
  }
`

const FooterMenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;

  @media (min-width: 991px) {
    justify-content: flex-end;
    display: flex;
  }

  > li {
    text-align: center;
    margin: 0 0.5rem 0.5rem;

    @media (min-width: 576px) {
      text-align: left;
    }

    > a {
    }
  }
`

const Footer = () => (
  <StyledFooter id="footer-global">
    <Inner>
      <Row>
        <Col sm="6">
          <Copyright>
            © {new Date().getFullYear()},{' '}
            <a href="https://www.medforum-dresden.de/index.html/vefa/ber-uns/verein-der-fachschaft-medizin-zahnmedizin-dresden-ev-r55/">
              Verein der Fachschaft Medizin/Zahnmedizin Dresden e.V.
            </a>
          </Copyright>
        </Col>
        <Col sm="6">
          <FooterMenuList>
            <li>
              <a href="https://docs.google.com/document/d/19JvY6CJudHyxjutZfqihuaMg01EdcDFlk6t2ssLcneY/edit">
                Regelwerk
              </a>
            </li>
            <li>
              <a href="https://www.medforum-dresden.de/impressum-datenschutzerkl%C3%A4rung/">
                Impressum & Datenschutz
              </a>
            </li>
          </FooterMenuList>
        </Col>
      </Row>

      <Row>
        <Col>
          <SmallText>
            <a
              href="https://airtable.com/shrcb9fC1WmpVpaZW"
              title="Fehler oder Verbesserungsvorschlag melden"
              rel="noopener noreferrer"
              target="_blank"
            >
              Fehler oder Verbesserungsvorschlag melden
            </a>
          </SmallText>
        </Col>
      </Row>
      {/* <FooterLogo
        alt="leicht+luftig Logo"
        src={require('../../images/logo/ll-logo.svg')}
      />

      <FooterMenuList>
        <li>
          <Link to="/datenschutz">Datenschutz</Link>
        </li>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
      </FooterMenuList> */}
    </Inner>
  </StyledFooter>
)

export default Footer
