// @flow
import React, { useState } from 'react'
import { Link, useStaticQuery, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container as ContainerGeneric } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/actions'

import * as v from '../../config/variables'
import { ButtonBlank } from '../UI'
import MobileMenu from './MobileMenu'

type Props = {
  siteTitle: string,
  user: Object,
  logoutUser: Function,
}

const StyledHeader = styled.header`
  background: ${v.white};
  height: 70px;
  margin-bottom: 1.45rem;

  @media (min-width: 576px) {
    height: 80px;
  }

  a {
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
`

const SiteId = styled.div`
  width: 170px;
  flex: 0 0 auto;

  img {
    margin: 0;
  }
`

const Logo = styled(Img)`
  max-width: 200px;
  /* margin: 1rem 0; */
`

const Container = styled(ContainerGeneric)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// const Inner = styled.div``

const NavMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
  margin: 0 -0.7rem;
`

const NavItem = styled.li`
  margin: 0;
`

const NavLink = styled(Link)`
  height: 100%;
  margin: 0 0.7rem;
  padding: 1rem 0;
  color: ${v.brandColor};
  font-family: ${v.fontFamilyAccent};
  font-weight: bold;
  font-size: 1.1rem;
  background-color: transparent;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
`

const DesktopMenu = styled.nav`
  /* display: flex; */
  display: none;
  height: 100%;

  @media (min-width: 576px) {
    display: flex;
  }
`

const Username = styled.span`
  color: ${v.gray};
  font-size: 0.8rem;
  font-family: ${v.fontFamilyBase};
  display: block;
  position: absolute;
  top: 54px;
  right: 12px;
`

const MobileMenuButton = styled(ButtonBlank)`
  color: ${v.brandColor};
  font-family: ${v.fontFamilyAccent};
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  padding: 1rem 0.5rem;

  @media (min-width: 576px) {
    display: none;
  }
`

const Header = ({ siteTitle, user, logoutUser }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo/logo-disscheck.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { logo } = data
  const { isLoggedIn, username } = user

  const handleLogoutUser = (e) => {
    e.preventDefault()
    logoutUser()
    navigate('/')
  }

  return (
    <StyledHeader>
      <Container>
        {/* <Inner> */}
        <SiteId>
          <Link to="/" style={{ display: 'block' }}>
            <Logo alt={siteTitle} fluid={logo.childImageSharp.fluid} />
          </Link>
        </SiteId>

        <MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
          Menü
        </MobileMenuButton>
        <MobileMenu
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onLogout={handleLogoutUser}
          isLoggedIn={isLoggedIn}
          username={username}
        />

        <DesktopMenu>
          <NavMenu>
            <NavItem>
              <NavLink to="/app/rezensionen">Rezensionen</NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/app/verfassen">Verfassen</NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/info">Info</NavLink>
            </NavItem>

            {!isLoggedIn && (
              <NavItem>
                <NavLink to="/login">Login</NavLink>
              </NavItem>
            )}

            {isLoggedIn && (
              <>
                <NavItem>
                  <NavLink as="a" onClick={(e) => handleLogoutUser(e)}>
                    Logout
                  </NavLink>
                  <Username>{username}</Username>
                </NavItem>
                {/* <li style={{ margin: 0 }}>
                  <span>{username}</span>
                </li> */}
              </>
            )}
          </NavMenu>
        </DesktopMenu>
        {/* </Inner> */}
      </Container>
    </StyledHeader>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function mapStateToProps({ user }) {
  return { user }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
