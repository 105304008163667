// @flow

import * as types from '../types'
import Api from '../../lib/api'

// type Credentials = { username: string, password: string }
type Authorization = { accessToken: string, expiresIn: string }

/**
 * Login a user
 * @param {Authorization} auth
 */
export const loginUser = (auth: Authorization): Function => (
  dispatch: Function
) => {
  const { accessToken } = auth

  return (
    Api.get('core/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      // return Api.get('users/1', {
      //   baseURL: 'https://jsonplaceholder.typicode.com/',
      //   headers: {
      //     Authorization: `Bearer ${accessToken}`,
      //     'Access-Control-Allow-Origin': '*',
      //   },
      // })
      .then((result) => {
        const { data } = result

        dispatch({ type: types.LOGIN_USER, auth, userData: data })
        return result
      })
      .catch((reason) => {
        return reason
        // console.log(reason)
      })
  )
}

export const sendMessage = ({
  recipientId,
  message,
}: {
  recipientId: string,
  message: string,
}) => (dispatch: Function, state: Function) => {
  const { user } = state()
  const {
    accessToken,
    email: senderEmail,
    id: senderId,
    username: senderName,
    profileUrl: senderProfileUrl,
  } = user

  return Api.post(
    `message`,
    {
      recipientId,
      senderId,
      senderName,
      senderEmail,
      senderProfileUrl,
      accessToken,
      message,
    },
    {
      baseURL: process.env.CLOUD_FUNCTIONS_API_BASE_URL,
      'Content-Type': 'application/json',
    }
  )
    .then((result) => {
      const { data } = result

      dispatch({ type: types.SEND_MESSAGE, data })
    })
    .catch((reason) => {
      throw reason
    })
}

// export const continueUser = (): Function => dispatch => {
//   const theClient = client.login()
//   dispatch({
//     type: types.CONTINUE_USER,
//     theClient,
//   })
// }

/**
 * Logout a user
 */
export const logoutUser = () => (dispatch: Function) => {
  dispatch({
    type: types.LOGOUT_USER,
  })
}
