// @flow
import axios from 'axios'
// import { configureStore } from '../store/configureStore'

// const store = configureStore().getState()

const api = axios.create({
  baseURL: process.env.API_BASE_URL,
  // responseType: 'json',
})

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // console.log(store)
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default api
