// @flow
import { createGlobalStyle } from 'styled-components'
import * as v from '../../config/variables'

export default createGlobalStyle`
  .pagination-container {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    list-style-type: none;
    justify-content: space-between;

    @media (min-width: 575px) { 
      justify-content: flex-start;
    }


    > li {
      
      > a {
        padding: 0.4rem 0.3rem;
        margin: 0 0.3rem;
        cursor: pointer;
        border: 1px solid ${v.brandColor};
        border-radius: 5px;
        min-width: 40px;
        height: 40px;
        display: flex;
        text-align: center;
        justify-content: center;

        &:hover, &:focus {
          box-shadow: none;
        }
        /* &:before {
          content: 'Seite: '
        } */
      }

      &.active {
        > a {
          background-color: ${v.brandColor};
          color: ${v.white}
        }
      }

      &.disabled {
          opacity: 0.5;

          > a {
            color: ${v.gray};
            border-color: ${v.gray};
          }
      }

      &:not(.previous):not(.next) {
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
  }
`
