// @flow

import React from 'react'
import { Link } from 'gatsby'
import ReactModal from 'react-modal'
import styled from 'styled-components'

import * as v from '../../config/variables'
import { ButtonBlank } from '../UI'

ReactModal.setAppElement('#___gatsby')
type Props = {
  isOpen: boolean,
  isLoggedIn: boolean,
  username: string,
  onClose: Function,
  onLogout: Function,
}

const CloseButton = styled(ButtonBlank)`
  color: ${v.textColor};
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 100;
  font-family: ${v.fontFamilyBase};
  position: absolute;
  top: -0.6rem;
  right: 1.1rem;
  z-index: 10;
`

const MobileNav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 20vh 0 0;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
`

const NavItem = styled.li`
  text-align: center;
`

const NavLink = styled(Link)`
  color: ${v.brandColor};
  font-size: 1.2rem;
  font-family: ${v.fontFamilyAccent};
  font-weight: bold;
  text-transform: uppercase;
`

const Username = styled.span`
  color: ${v.gray};
  font-size: 0.8rem;
  font-family: ${v.fontFamilyBase};
  display: block;
`

const MobileMenu = (props: Props) => {
  const { isOpen, onClose, isLoggedIn, username, onLogout } = props

  return (
    <ReactModal
      /*
    Boolean describing if the modal should be shown or not.
  */
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Mobiles Menü"
      style={{
        overlay: { zIndex: 100 },
        content: { inset: 0, borderRadius: '0', border: 'none' },
      }}
    >
      <CloseButton onClick={onClose}>×</CloseButton>

      <MobileNav>
        <NavItem>
          <NavLink to="/app/rezensionen">Rezensionen</NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/app/verfassen">Verfassen</NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/info">Info</NavLink>
        </NavItem>

        {!isLoggedIn && (
          <NavItem>
            <NavLink to="/login">Login</NavLink>
          </NavItem>
        )}

        {isLoggedIn && (
          <NavItem>
            <NavLink as="a" onClick={onLogout}>
              Logout
            </NavLink>
            <Username>{username}</Username>
          </NavItem>
        )}
      </MobileNav>
    </ReactModal>
  )
}

export default MobileMenu
