// @flow
const mapAirtableData = (airtableData: Object) => {
  return airtableData.edges.reduce((prev, curr) => {
    const {
      node: { data },
    } = curr
    prev[data.Name] = data.Text || {}
    return prev
  }, {})
}

export default mapAirtableData
